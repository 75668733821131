import React, { useState, useLayoutEffect } from "react";
import ReactMarkdown from "react-markdown";

import Info from "@templates/Info";
import ContentBox from "@atoms/ContentBox";
import Icon from "@atoms/Icon";
import Text from "@atoms/Text";

import { termsOfUse } from "@content/termsOfUse";
import FaqSvg from "@images/icons/question-mark-circle.svg";

const FaqPage = () => {
  const [startPage, setStartPage] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setStartPage(true), 500);
  }, [setStartPage]);

  return (
    <Info active={startPage}>
      <ContentBox>
        <h2>
          <Icon src={FaqSvg} /> Frequently Asked Questions
        </h2>
      </ContentBox>
      <ContentBox>
        <Text>
          <h3>What is fplrank.live?</h3>
          <p>
            Fplrank.live is your ultimate Fantasy Premier League sidekick.
            Crafted with mini leagues in mind, it transforms the game by
            effortlessly grouping live GW points, captaincy, transfers, and more
            into one user-friendly view. Perfect for all mini league members, it
            injects an extra dose of enjoyment and connection into the FPL
            experience. Your league is leveling up in excitement with
            fplrank.live app!
          </p>

          <h3>What’s the story behind fplrank.live?</h3>
          <p>
            Initially, we crafted fplrank.live for our mini league with a
            handful of mates. It transformed the game for us, consolidating all
            crucial info - live GW points, captaincy, transfers, and more - into
            one seamless view. As we delved deeper, we amped it up with features
            like ownership breakdowns, player-specific owners, transfer costs,
            bench points, and charts. And guess what? This is just the
            beginning! We're brewing up more features to make FPL mini leagues
            even more fun and exciting.
          </p>

          <h3>Leagues up to 50 teams? Why?</h3>
          <p>
            Currently, loading more data for larger leagues could result in
            slower response times. But fear not! We're actively working to
            enhance the system, making it possible to accommodate leagues larger
            than 50 teams in the near future.
          </p>

          <h3>FPL is free. Why do I have to pay for fplrank.live?</h3>
          <p>
            We understand cost considerations are crucial. Our pricing reflects
            the investment in servers, domain maintenance, and continuous
            improvements to enhance your FPL experience. To put it in
            perspective, for a league with 10 members, it breaks down to just
            £0.5/month per member. We think that’s a pocket-friendly monthly
            contribution per member for a season packed with enhanced enjoyment.
          </p>

          <h3>
            What are the payment options, and can I trust that it's secure?
          </h3>
          <p>
            Head to the payments page and select your preferred product (monthly
            access or end-of-season). Upon checkout, you'll be seamlessly
            redirected to our payment partner. Please note that all transactions
            are securely processed by PayPal - rest assured, your financial
            details remain confidential within PayPal's secure system and are
            never shared with us.
          </p>

          {/* <h3>Why are there numerous Leeds-related gifs in the loader?</h3>
          <p>
            We're thrilled you noticed! As a devoted Leeds United fan since
            ~2001, we couldn't resist injecting some personal flair into the
            loading experience. Each gif reflects our passion for the mighty
            Leeds, creating a unique and spirited touch as you await the magic
            of your FPL data to unfold. But hey, it's not all about Leeds—there
            are also some epic gifs representing other teams. At fplrank.live,
            we're not just about Leeds; we're all about the beautiful game, the
            Premier League, and the thrilling world of FPL. Every gif is a
            celebration of our shared love for football and the excitement that
            comes with it!
          </p> */}
        </Text>
      </ContentBox>
    </Info>
  );
};

export default FaqPage;
