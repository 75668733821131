export const termsOfUse = {
  content: `
### §1 Basic information
1. These regulations, hereinafter referred to as the Regulations, specify the principles of operation of the fplrank.live website, hereinafter referred to as the Website, available at www.fplrank.live, and the rules for the provision of electronic services through the Website by the Administrator who owns the Website.
2. The Regulations are published on the Website at www.fplrank.live/terms/
3. The Website is owned by kobylarz.co from Bojanow, ul. Tarnobrzeska 20, 37-433 Bojanow, NIP: 8652522102.
4. The Website Owner can be contacted by e-mail at contact@fplrank.live or by phone at +48 788 926 188.
5. In the scope of Services Provided by Electronic Means, the Regulations are the regulations referred to in art. 8 of the Act of July 18, 2002 on the provision of electronic services (Journal of Laws of 2002, No. 144, item 1204, as amended).
### §2 Using the Website
1. The website was created to allow FPL managers to check live standings of their mini leagues.
2. Users agree not to use the App for any illegal or unauthorized purpose.
4. Subscription fees are billed on a monthly or season basis.
5. The subscription fee is £10 or £5 per month.
### §3 Trial period
1. Each new league is entitled to 1 (one) free trial period to test the functionality of the website.
2. The trial period gives the user free access to the website's functionality for a period of 7 days from the moment of first load of the league page.
3. Abuse of the trial period to avoid fees may result in the account and all content being blocked.
### §4 Rights and obligations
1. The Website reserves the right to temporarily cease to provide services due to the repair or modernization of the Website. Information about these types of activities will appear on the Website.
2. The User is obliged to use the Website in a manner consistent with the law and decency, having regard to respect for personal rights and material copyrights of the Website Owner and personal copyrights of Photography and third parties.
3. The user may only use the account for lawful purposes.
### §5 Minimum technical requirements
To use the Website, you must have the following equipment:
1. PC, smartphone, tablet or other device usually used to browse websites with the most up-to-date software version, by which it is possible to download and save files from the Internet.
2. Internet connection (at least 10 Mbps recommended);
3. current version of Firefox, Chrome, Safari or Microsoft Edge web browser;
4. enabled JavaScript, frames and CSS styles.
### §6 Website responsibility
1. Despite the application of the highest security standards, the Website cannot be held responsible for temporary and unexpected disabling of the Website's functionalities as a result of hacker attacks, random causes, natural disasters, force majeure or other circumstances beyond the Website's control.
### §7 Complaint procedure
1. In the event of unsatisfactory quality of Services provided by the Website Owner or other entities on his behalf, or in the event of the Website Owner's failure to perform, or improper performance of obligations towards Users, the Users shall be entitled to file a complaint.
2. Complaints should be reported by sending an e-mail to contact@fplrank.live, with the subject "complaint" in the subject of the message.
3. A correctly submitted complaint should contain at least the following data:
* Users's name (name and surname, correspondence address, e-mail address).
* brief description of the comments and reservations submitted;
* photo documentation if possible.
4. Complaints are handled by the Website Owner immediately when possible, at the latest within 14 days.
### §8 Dispute resolution
1. In the event of disputes, the Website suggests using amicable settlement methods.
2. Any litigation will be subject to jurisdiction in the court competent for the Website Owner.
3. The website informs that at http://ec.europa.eu/consumers/odr there is an online platform for settling disputes between consumers and entrepreneurs. It is a one-stop shop for out-of-court settlement of disputes regarding contractual obligations under an online sales contract or service contract.
4. In the event of a dispute with the User, the Website proposes to use mediation at the Voivodship Inspectorate of Trade Inspection, competent for the seat of the Consumer, or use arbitration at the Permanent Consumer Arbitration Court competent for the seat of the Consumer.
### §9 Final Provisions
1. The Regulations entered into force on November 10, 2023
2. The Service Provider reserves the right to change the Regulations after 14 days from posting relevant information on the Website and sending information to Users via e-mail.
3. The provisions of the Regulations do not exclude any rights of Consumers entitled to them under generally applicable law.
4. In cases not covered by the Regulations, the provisions of generally applicable law shall apply.`,
};
